<template>
<swiper
    :slidesPerView="1"
    loop
  >
    <swiper-slide class="slide">
        <img src="../../assets/slider_0.jpg" />
    </swiper-slide>
    <swiper-slide class="slide">
        <img src="../../assets/slider_1.jpg" />
    </swiper-slide>
    <swiper-slide class="slide">
        <img src="../../assets/slider_2.jpg" />
    </swiper-slide>
  </swiper>
</template>
<script>
  import { Pagination } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/swiper.min.css'
//   import 'swiper/';
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
      };
    },
    setup() {
      return {
        modules: [Pagination]
      }
    }
  };
</script>
<style lang="less">

.swiper {
  width: 100%;
  height: 30rem;
}

.swiper-slide{
//   height: 100vh;
  width: 100%;
  img {
    width: 100%;
    // height: 100vh;
  }
}

</style>