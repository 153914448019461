<template>
  <footer class="footer">
    <img src="../../assets/bottom.jpg" />
      <div class="footer-bottom">
        <p>Copyright © 成都斯波特科技有限责任公司</p>
        <p>蜀ICP备2023042926号</p>
      </div>
  </footer>
</template>
<script>
export default {
  name: 'HomeFooter',
}
</script>
<style scoped lang="less">
.footer {
  width: 100%;
  // height: 9rem;
  background: #fff;
  img {
    width: 100%;
  }
  .footer-top {
    font-size: 0;
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  .footer-bottom {
    width: 100%;
    height: 1.24rem;
    position: absolute;
    bottom: 1.2rem;
    text-align: center;
    line-height: .6rem;

    p,
    a {
      display: block;
      font-size: .3rem;
      color: white;
      text-align: center;
      line-height: .6rem;
    }
  }
}</style>