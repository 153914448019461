<template>
    <div class="content-section">
        <img src="../../assets/content.jpg" />
    </div>
</template>
<script>
export default {
    name: "ContentSection"

}
</script>
<style lang="less">
.content-section {
    margin-top: -.2rem;
    img {
        display: block;
        width: 100%;
        margin: 0;
        
    }

}
</style>