<template>
  <FixedTopBar />
  <TopSwiper />
  <ContenSection />
  <HomeFooter />
</template>

<script>
import TopSwiper from "./components/TopSwiper.vue";
import FixedTopBar from "./components/FixedTopBar.vue"
import HomeFooter from "./components/Footer.vue"
import ContenSection from "./components/ContenSection.vue"

export default {
  name: 'App',
  components: {
    FixedTopBar,
    TopSwiper,
    ContenSection,
    HomeFooter
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}
body,html {
  margin: 0;padding: 0;
}
</style>
